import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = () => {
  return (
    <section id="videoplayer">
      <div className="container">
        <div className="row">
          <div className="videoplayer__wrapper">
            <h1 className="section__title">Profile Video</h1>
            <div className="green-divider"></div>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=yHTQ1CrUF6I&t=179s"
              width="100%"
              height="500px"
              playing
              controls
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoPlayer;
