import React from "react";
import { NavLink } from "react-router-dom";

const TentangDKET = () => {
  return (
    <>
      <section id="tentangdket">
        <div className="container">
          <div className="row">
            <div className="tentangdket__wrapper">
              <h1 className="section__title">Tentang DKET</h1>
              <div className="green-divider"></div>
              <p className="section__para">
                Indonesia memiliki target transisi energi nasional melalui
                bauran energi baru dan terbarukan sebesar 23% pada tahun 2025.
                Sektor energi terbarukan pun memainkan peran yang signifikan
                untuk pertumbuhan ekonomi hijau nasional. Perluasan teknologi
                energi terbarukan memainkan peran kunci, namun hal ini perlu
                didukung dengan kesiapan sumber daya manusia di sisi kompetensi
                dan kualifikasi agar mampu berkontribusi untuk pencapaian target
                dan menjawab tantangan yang ada.
              </p>
              <NavLink to="/about" className="tentangdket__button">
                Selengkapnya
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TentangDKET;
