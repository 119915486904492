import React from 'react'

const Partners = () => {
  return (
    <>
    <section id="partners">
      <div className="container">
        <div className="row">
          <div className="partners__wrapper">
            <h1 className="section__title">Difasilitasi Oleh</h1>
            <div className="green-divider"></div>
            <div className="partners__banner"></div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}

export default Partners