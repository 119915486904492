import React from "react";

const AboutDetails = () => {
  return (
    <>
      <section id="tentangdket">
        <div className="container">
          <div className="row">
            <div className="tentangdket__wrapper">
              <p className="section__para">
                Saat ini, dunia menghadapi sebuah tantangan global untuk
                mencapai perekonomian yang berkelanjutan dan ramah lingkungan,
                dan sektor energi terbarukan berada di garis depan dalam
                mengemban misi ini. Untuk mendukung pertumbuhan dan inovasi
                sektor energi terbarukan, diperlukan sebuah platform yang dapat
                menghubungkan industri dengan penyedia pendidikan dan pelatihan,
                serta mengembangkan keterampilan dan kompetensi tenaga kerja.
                <br/>
                <br/>
                Oleh karenanya, lahirlah gagasan untuk sebuah konsep Dewan
                Keterampilan Energi Terbarukan, yang mampu memfasilitasi
                kolaborasi dan sinergi berbagai pemangku kepentingan, serta
                memberikan solusi terhadap kebutuhan pengembangan sumber daya
                manusia di bidang energi terbarukan. Konsep DKET ini juga
                selaras dengan implementasi Strategi Nasional Vokasi Indonesia
                dan bagian kunci dari Strategi Peta Jalan Green Jobs di
                Indonesia.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutDetails;
