import React from "react";
import { FaEye } from "react-icons/fa";

const Visi = () => {
  return (
    <>
      <section id="visi">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="visi__wrapper">
              <div className="visi__wrapper--left">
                <h1 className="visi__headline">VISI</h1>
                <FaEye className="eye"/>
              </div>
              <div className="visi__wrapper--right">
                <h2 className="landing__subheadline">
                  Menjadi “Center of Excellence” bagi pengembangan keterampilan
                  sumber daya manusia di sektor energi terbarukan, untuk
                  mendukung transisi energi menuju Net Zero Emission.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Visi;
