import React from "react";
import { NavLink as NavBarLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { useState } from "react";
import styled from "styled-components";
import DKETLogo from "../assets/DKET Logo Full.png";

export const Nav = styled.div`
  background-color: #4caf50;
  opacity: 0.9;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  @media (max-width: 960px) {
    trastion: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1200px;
`;

export const Span = styled.div`
  padding: 0 4px;
  font-weight: bold;
  font-size: 18px;
`;

export const NavItems = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 0 6px;
  list-style: none;
  text-decoration: none;

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

export const NavLink = styled(NavBarLink)`
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  :hover {
    color: #000000;
  }

  &.active {
    font-weight: bold;
  }
`;

export const FooterLink = styled.a`
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  :hover {
    color: #000000;
  }
`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 1200px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.5rem;
    cursor: pointer;
    color: #ffffff;
  }
`;

export const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  position: absolute;
  top: 80px;
  right: 0;
  width: 100%;
  padding: 12px 40px 24px 40px;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.6s ease-in-out;
  transform: ${({ isOpen }) =>
    isOpen ? "translateY(0)" : "translateY(-100%)"};
  border-radius: 0 0 20px 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  z-index: ${({ isOpen }) => (isOpen ? "1000" : "-1000")};
`;

export const MobileMenuItems = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  list-style: none;
  width: 100%;
  height: 100%;
`;

export const MobileMenuLink = styled(NavBarLink)`
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  :hover {
    color: green;
  }

  &.active {
    font-weight: bold;
  }
`;

export const MobileLink = styled(NavBarLink)`
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  :hover {
    color: #ffa500;
  }

  &.active {
    font-weight: bold;
  }
`;

export const NavLogoWrapper = styled(NavBarLink)`
  padding: 10px;
  max-width: 600px;
`;

export const NavLogo = styled.img`
  width: 20%;
`;

export const FooterMobileLink = styled(NavBarLink)`
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  :hover {
    color: #000;
  }
`;

const NavBar = ({ toggleModal}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Nav>
      <NavbarContainer>
        <MobileIcon>
          <FaBars
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        </MobileIcon>
        <NavLogoWrapper to="/">
          <NavLogo src={DKETLogo} alt="logo"></NavLogo>
        </NavLogoWrapper>
        <NavItems>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about">About Us</NavLink>
          <NavLink to="/publication">Publication</NavLink>
          <NavLink to="/services">Services</NavLink>
          <FooterLink
            onClick={toggleModal}
          >
            Contact Us
          </FooterLink>
        </NavItems>
        {isOpen && (
          <MobileMenu isOpen={isOpen}>
            <MobileLink
              link
              to="/"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              Home
            </MobileLink>
            <MobileLink
              link
              to="/about"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              About Us
            </MobileLink>
            <MobileLink
              link
              to="/publication"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              Publication
            </MobileLink>
            <MobileLink
              link
              to="/services"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              Services
            </MobileLink>
            <FooterMobileLink
              onClick={toggleModal}
            >
              Contact Us
            </FooterMobileLink>
          </MobileMenu>
        )}
      </NavbarContainer>
    </Nav>
  );
};

export default NavBar;
