import React from "react";

const Landing = () => {
  return (
    <>
      <section id="landing">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
          <div className="landing__background"></div>
            <div className="landing__wrapper">
              <div className="landing__wrapper--left">
                
                <h1 className="landing__headline">
                  Dewan Keterampilan Energi Terbarukan (DKET)
                </h1>
                <br></br>
                <h2 className="landing__subheadline">
                  Sebuah platform sinergi yang berfokus pada pengembangan
                  keterampilan dan kompetensi Sumber Daya Manusia Energi
                  Terbarukan menuju transisi energi yang berkesinambungan untuk
                  Indonesia.
                </h2>
              </div>
              <div className="landing__wrapper--right"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Landing;
