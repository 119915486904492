import React from 'react'
import Landing from '../components/Landing'
import TentangDKET from '../components/TentangDKET'
import LayananKami from '../components/LayananKami'
import Partners from '../components/Partners'
import PublikasiLanding from '../components/PublikasiLanding'

const HomePage = () => {
  return (
    <div>
      <Landing/>
      <TentangDKET/>
      <PublikasiLanding/>
      <LayananKami/>
      <Partners/>
    </div>
  )
}

export default HomePage