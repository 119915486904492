import React from "react";
import DKETLogo from "../assets/DKET Icon.png";

const AboutLanding = () => {
  return (
    <>
      <section id="aboutlanding">
      <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="aboutlanding__wrapper">
              <div className="aboutlanding__wrapper--left">
                <div className="aboutlanding__logo--wrapper">
                  <img src={DKETLogo} alt="DketLogo" className="aboutlanding__logo" loading="lazy"/>
                </div>
              </div>
              <div className="aboutlanding__wrapper--right">
                <h2 className="landing__subheadline">
                  Sebuah platform sinergi yang berfokus pada pengembangan
                  keterampilan dan kompetensi Sumber Daya Manusia Energi
                  Terbarukan menuju transisi energi yang berkesinambungan untuk
                  Indonesia.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutLanding;
