import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import Publication from "./pages/Publication";
import Services from "./pages/Services";
import ContactModal from "./components/ContactModal";
import { useState, useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    document.body.classList.toggle("modal--open", isModalOpen);
  }, [isModalOpen]);

  return (
    <Router>
      <NavBar toggleModal={toggleModal} />
      {isModalOpen && <ContactModal closeModal={toggleModal} />}
      <RoutesWithTransitions />
      <Footer />
    </Router>
  );
}

const useScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]); // Triggered on location change
};

function RoutesWithTransitions() {
  const location = useLocation(); // Correctly called within a component that is a child of <Router>
  useScrollToTop();
  
  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Routes location={location}>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/publication" element={<Publication />} />
          <Route path="/services" element={<Services />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default App;
