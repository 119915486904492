import React from "react";
import Publikasi from "../components/Publikasi";
import VideoPlayer from "../components/VideoPlayer";

const Publication = () => {
  return (
    <>
      <Publikasi />
      <VideoPlayer />
    </>
  );
};

export default Publication;
