import React from "react";
import TrainingCenter from "../assets/Asset 2@4x.png";
import LinkAndMatch from "../assets/Asset 3@4x.png";
import BenchmarkingClearing from "../assets/Asset 4@4x.png";
import Innovation from "../assets/Asset 5@4x.png";
import ReferenceOfCompetence from "../assets/Asset 6@4x.png";
import PolicyAdvice from "../assets/Asset 7@4x.png";
import ExpertPool from "../assets/Asset 8@4x.png";

const LayananKami = () => {
  const layananKami = [
    { name: "Training Center", logo: TrainingCenter },
    { name: "Link and Match", logo: LinkAndMatch },
    { name: "Benchmarking/Clearing House", logo: BenchmarkingClearing },
    { name: "Innovation", logo: Innovation },
    { name: "Reference of Competence Standard", logo: ReferenceOfCompetence },
    { name: "Policy Advice", logo: PolicyAdvice },
    { name: "Expert Pool", logo: ExpertPool },
  ];
  return (
    <>
      <section id="layanankami">
        <div className="container">
          <div className="row">
            <div className="layanankami__wrapper">
              <h1 className="section__title">Layanan Kami</h1>
              <div className="orange-divider"></div>
              <div className="layanankami__list">
                {layananKami.map((layanan, index) => (
                  <div className="layanankami" key={index}>
                    <figure className="layanankami__img--wrapper">
                      <img
                        src={layanan.logo}
                        alt={`${layanan.name} logo`}
                        className="layanankami__img"
                        loading="lazy"
                      />
                    </figure>
                    <span className="layanankami__name">{layanan.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LayananKami;
