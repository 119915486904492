import React from "react";
import styled from "styled-components";

const Card = styled.div`
  margin-top: 40px;
  width: 300px;
  height: 500px;
  background-color: #4caf50;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 26px 20px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  transition: all 0.5s ease-in-out;
  justify-content-center;
  align-items: center;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 50px 4px rgba(0, 0, 0, 0.6);
    filter: brightness(1.1);
    background-color: #ffa500;
  }
`;

const Image = styled.img`
  width: 50%;
`;

const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0px 2px;
  text-align: center;
  color: white;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.primary};
  overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.black + 99};
  overflow: hidden;
  margin-top: 8px;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const ServicesCard = ({ services }) => {
  return (
    <Card>
      <Image src={services.image} alt="services_image" loading="lazy" />
      <Details>
        <Title>{services.title}</Title>
        <Description>{services.description}</Description>
      </Details>
    </Card>
  );
};

export default ServicesCard;
