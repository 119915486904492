import React from 'react'
import AboutLanding from '../components/AboutLanding'
import AboutDetails from '../components/AboutDetails'
import Visi from '../components/Visi'
import Misi from '../components/Misi'
import NilaiUtama from '../components/NilaiUtama'
import TargetJangkaPendek from '../components/TargetJangkaPendek'
import Roadmap from '../components/Roadmap'

const AboutPage = () => {
  return (
    <>
    <AboutLanding/>
    <AboutDetails/>
    <Visi/>
    <Misi/>
    <NilaiUtama/>
    <TargetJangkaPendek/>
    <Roadmap/>
    </>
  )
}

export default AboutPage