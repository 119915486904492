import React from "react";
import publication from '../assets/vertical-shot-white-wind-fan-standing-green-field-mountains.jpg';
import { NavLink } from "react-router-dom";

const PublikasiLanding = () => {
  return (
    <>
      <section id="publikasi">
        <div className="container">
          <div className="row">
            <div className="publikasi__wrapper">
              <div className="publikasi__wrapper--left">
              <img src={publication} alt="publication" className="publication" loading="lazy"/>
              </div>
              <div className="publikasilanding__wrapper--right">
                <h1 className="section__title">
                  Model Dewan Keterampilan Energi Terbarukan (DKET)
                </h1>
                <div className="green-divider"></div>
                <NavLink to="/publication" className="publikasi__button">Selengkapnya</NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PublikasiLanding;
