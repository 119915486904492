import React from "react";
import MisiCard from "./MisiCard";

const TargetJangkaPendek = () => {
  return (
    <>
      <section id="targetjangkapendek">
        <div className="container">
          <div className="row">
            <div div className="targetjangkapendek__wrapper">
              <h1 className="section__title">
                Target Jangka Pendek 2024 → 2028
              </h1>
              <div className="green-divider"></div>
              <div className="targetjangkapendek__card--container">
                <MisiCard
                  text="1. Renewable Energy Skill Center untuk memfasilitasi pengembangan kompetensi serta sertifikasi SDM, 500 orang di tahun 2028
"
                />
                <MisiCard
                  text="2. Sinergi dan “link and match” industry dengan pendidikan dan penelitian melalui 1000 MoU kerjasama dan 250 tenaga pengajar industri
"
                />
                <MisiCard
                  text="3. Kebijakan Pro Pasar untuk meningkatkan kapasitas SDM sektor RE didukung oleh Resource Hub SDM ET
"
                />
                <MisiCard
                  text="4. Sumber pendanaan mandiri
"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TargetJangkaPendek;
