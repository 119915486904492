import React from "react";
import publication from '../assets/vertical-shot-white-wind-fan-standing-green-field-mountains.jpg';
import DKETDeckModel from '../assets/Rev 10 Juni - Model & Strategy DKET (ID).pdf'


const Publikasi = () => {
  return (
    <>
      <section id="publikasi">
        <div className="container">
          <div className="row">
            <div className="publikasi__wrapper">
              <div className="publikasi__wrapper--left">
              <img src={publication} alt="publication" className="publication" loading="lazy"/>
              </div>
              <div className="publikasi__wrapper--right">
                <h1 className="section__title">
                  Model Dewan Keterampilan Energi Terbarukan (DKET)
                </h1>
                <div className="green-divider"></div>
                <p className="section__para">
                  Model DKET dirumuskan oleh tim Gugus Tugas yang terdiri dari
                  perwakilan industri energi terbarukan, lembaga pendidikan dan
                  lembaga sertifikasi profesi. Model ini telah melalui tahapan
                  konsultasi publik dan dalam proses harmonisasi menuju
                  publikasi yang akan rampung di bulan Juni 2024.
                </p>
                <a href={DKETDeckModel} download="Deck Model DKET" className="publikasi__button">Unduh</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Publikasi;
