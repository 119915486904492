import React from "react";
import ServicesCard from "./ServicesCard";
import styled from "styled-components";
import TrainingCenter from "../assets/Asset 2@4x.png";
import LinkAndMatch from "../assets/Asset 3@4x.png";
import BenchmarkingClearing from "../assets/Asset 4@4x.png";
import Innovation from "../assets/Asset 5@4x.png";
import ReferenceOfCompetence from "../assets/Asset 6@4x.png";
import PolicyAdvice from "../assets/Asset 7@4x.png";
import ExpertPool from "../assets/Asset 8@4x.png";

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 28px;
  flex-wrap: wrap;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
  grid-auto-rows: minmax(100px, auto);
  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const OurServices = () => {
  const layananKami = [
    {
      title: "Training Center",
      image: TrainingCenter,
      description:
        "DKET memfasilitasi beragam kebutuhan pelatihan dan sertifikasi kompetensi industri energi terbarukan dan mensinergikannya kepada badan-badan pelatihan dan sertifikasi yang mumpuni. ",
    },
    {
      title: "Link and Match",
      image: LinkAndMatch,
      description:
        "DKET memfasilitasi “link and match” antara kebutuhan industri dan lembaga diklat/vokasi melalui fasilitasi penyelarasan kurikulum, fasilitasi program pemagangan, dan fasilitasi program pelatih industri.",
    },
    {
      title: "Benchmarking/Clearing House",
      image: BenchmarkingClearing,
      description:
        "DKET memfasilitasi kebutuhan benchmarking industri melalui beragam pendekatan dan platform berbagi pengetahuan lainnya.",
    },
    {
      title: "Innovation",
      image: Innovation,
      description:
        "DKET berperan dalam percepatan inovasi teknologi dan kemajuan bagi industri energi terbarukan Indonesia dengan platform sinergi dengan lembaga pendidikan dan riset.",
    },
    {
      title: "Reference of Competence Standard",
      image: ReferenceOfCompetence,
      description:
        "DKET memfasilitasi acuan/referensi untuk kebutuhan standardisasi kompetensi berbasis kebutuhan industri dan SKKNI.",
    },
    {
      title: "Policy Advice",
      image: PolicyAdvice,
      description:
        "DKET berperan dalam percepatan inovasi teknologi dan kemajuan bagi industri energi terbarukan Indonesia dengan platform sinergi dengan lembaga pendidikan dan riset.",
    },
    {
      title: "Expert Pool",
      image: ExpertPool,
      description:
        "DKET membuka akses terhadap kebutuhan akan provisi tenaga ahli yang terbaik di bidang energi terbarukan, baik lokal maupun internasional.",
    },
  ];
  return (
    <>
      <section id="ourservices">
        <div className="container">
          <div className="row">
            <div className="ourservices__wrapper">
              <h1 className="section__title">Layanan Kami</h1>
              <div className="green-divider"></div>
              <CardContainer>
                {layananKami.map((services) => (
                  <ServicesCard services={services} />
                ))}
              </CardContainer>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurServices;
