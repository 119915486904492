import React from "react";

const SKILLRE = ({ letter }) => {
  return (
    <div class="circle-icon">
      <span>{letter}</span>
    </div>
  );
};

export default SKILLRE;