import { React } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSpinner } from "@fortawesome/free-solid-svg-icons";
import emailjs from "emailjs-com";

const ContactModal = ({ closeModal }) => {
  const sendEmail = (event) => {
    event.preventDefault();
    const loading = document.querySelector(".modal__overlay--loading");
    const success = document.querySelector(".modal__overlay--success");
    loading.classList.add("modal__overlay--visible");

    emailjs
      .sendForm(
        "service_9i8g4oi",
        "template_ro5h8gf",
        event.target,
        "5RFxtB5tKrCQ_h8At"
      )
      .then(() => {
        loading.classList.remove("modal__overlay--visible");
        setTimeout(() => {
          success.classList.add("modal__overlay--visible");
        }, 0);
      })
      .catch(() => {
        loading.classList.remove("modal__overlay--visible");
        alert(
          "The email service is temporarily unavailable. Please contact me directly on malidzulfiqar@gmail.com"
        );
      });
  };

  return (
    <>
      <div className="modal">
        <div className="modal__half modal__about">
          <h3 className="modal__title modal__title--about">Hubungi Kami!</h3>
          <h4 className="modal__sub-title modal__sub-title--about">
            Jika tertarik untuk berkolaborasi, berdiskusi, atau ingin memberikan
            kritik dan saran, silakan hubungi kami dengan mengisi formulir
            berikut atau silakan menghubungi melalui email menuju alamat berikut:
            <br></br>
            <br></br>
            DewanKeterampilanET@gmail.com
          </h4>
        </div>
        <div className="modal__half modal__contact">
          <FontAwesomeIcon
            icon={faTimes}
            className="modal__exit click"
            onClick={closeModal}
          />
          <form onSubmit={sendEmail} id="contact__form">
            <div className="form__item">
              <label className="form__item--label">Nama</label>
              <input type="text" className="input" name="user_name" required />
            </div>
            <div className="form__item">
              <label className="form__item--label">Alamat Email</label>
              <input
                type="email"
                className="input"
                name="user_email"
                required
              />
            </div>
            <div className="form__item">
              <label className="form__item--label">Pesan</label>
              <textarea
                type="text"
                className="input"
                name="message"
                required
              ></textarea>
            </div>
            <button id="contact__submit" className="form__submit">
              Hubungi Kami
            </button>
          </form>
          <div className="modal__overlay modal__overlay--loading">
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
          <div className="modal__overlay modal__overlay--success">
            Pesan berhasil terkirim.
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactModal;
