import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import HandshakeIcon from "@mui/icons-material/Handshake";
import CellTowerIcon from "@mui/icons-material/CellTower";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const Roadmap = () => {
  return (
    <>
      <section id="roadmap">
        <div className="container">
          <div className="row">
            <div className="landing__wrapper">
              <Timeline position="alternate">
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    variant="body2"
                    color="text.secondary"
                  ></TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="primary">
                      <FlagCircleIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "24px", px: 2 }}>
                    <Typography variant="h5" component="span">
                      2024
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                  ></TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>
                      <HandshakeIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "24px", px: 2 }}>
                    <Typography variant="h5" component="span">
                      2025
                    </Typography>
                    <Typography>
                      Master Plan RE Skill Center Mitra pendanaan Skill Center
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    variant="body2"
                    color="text.secondary"
                  ></TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="primary"></TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "24px", px: 2 }}>
                    <Typography variant="h5" component="span">
                      2026
                    </Typography>
                    <Typography>
                      Pengkinian Peta Okupasi Sarana Pelatihan Proyek Kerja Sama
                      Penelitian
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>
                      <CellTowerIcon />
                    </TimelineDot>
                    <TimelineConnector/>
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "24px", px: 2 }}>
                    <Typography variant="h5" component="span">
                      2027
                    </Typography>
                    <Typography>Resource Hub SDM ET</Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="primary">
                      <FitnessCenterIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "24px", px: 2 }}>
                    <Typography variant="h5" component="span">
                      2028
                    </Typography>
                    <Typography>
                      50 Paket Kurikulum Pelatihan 1000 MoU Pendidikan 250
                      Pengajar Praktisi
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                  ></TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>
                      <MonetizationOnIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "24px", px: 2 }}>
                    <Typography variant="h5" component="span">
                      2029
                    </Typography>
                    <Typography>Pendanaan Mandiri</Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    variant="body2"
                    color="text.secondary"
                  ></TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="primary">
                      <AlternateEmailIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "24px", px: 2 }}>
                    <Typography variant="h5" component="span">
                      2030
                    </Typography>
                    <Typography></Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>
                      <EditIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "24px", px: 2 }}>
                    <Typography variant="h5" component="span">
                      2031
                    </Typography>
                    <Typography>RE Skill Center</Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="primary">
                      <CheckBoxIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "24px", px: 2 }}>
                    <Typography variant="h5" component="span">
                      2032
                    </Typography>
                    <Typography>
                      Link & Match Industri - Pendidikan Kebijakan SDM RE Pro
                      Pasar
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="secondary"></TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "24px", px: 2 }}>
                    <Typography variant="h5" component="span">
                      2033
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Roadmap;
