import React from "react";
import SKILLRE from "./SKILLRE";

const NilaiUtama = () => {
  const nilaiUtama = [
    { name: "Sustainable", letter: "S" },
    { name: "Knowledgeable", letter: "K" },
    { name: "Inclusive", letter: "I" },
    { name: "Learning", letter: "L" },
    { name: "Lively", letter: "L" },
    { name: "Resilient",letter: "R" },
    { name: "Enlightened", letter: "E" },
  ];
  return (
    <>
      <section id="nilaiutama">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="nilaiutama__wrapper">
              <h1 className="section__title">Nilai Utama</h1>
              <div className="green-divider"></div>
              <div className="nilaiutama__list">
                {nilaiUtama.map((nilaiutama, index) => (
                  <div className="nilaiutama" key={index}>
                    <figure className="nilaiutama__img--wrapper">
                      <SKILLRE letter={nilaiutama.letter}/>
                    </figure>
                    <span className="nilaiutama__name">{nilaiutama.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NilaiUtama;
