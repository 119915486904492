import React from "react";
import MisiCard from "./MisiCard";
import { FaBullseye } from "react-icons/fa";

const Misi = () => {
  return (
    <>
      <section id="misi">
        <div className="container">
          <div className="row">
            <div className="misi__wrapper">
              <div className="misi__wrapper--left">
                <h1 className="misi__headline">MISI</h1>
                <div className="misi__logo--wrapper">
                  <FaBullseye className="bullseye"/>
                </div>
              </div>
              <div className="orange-divider"></div>
              <div className="misi__wrapper--right">
                <MisiCard text="1. Memfasilitasi sinergi antara Pemerintah, dunia industri dan usaha, dunia Pendidikan, Lembaga riset, mitra pembangunan dan pihak lain yang terkait dalam meningkatkan keterampilan di bidang energi terbarukan." />
                <MisiCard text="2. Mengembangkan kerangka kerja yang diperlukan sebagai standar, kurikulum, metodologi, dan penjaminan mutu untuk meningkatkan keterampilan di bidang energi terbarukan." />
                <MisiCard text="3. Memberikan evaluasi dan rekomendasi terkait kebijakan dan pengembangan keterampilan di bidang energi terbarukan." />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Misi;
