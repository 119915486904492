import React, { forwardRef } from "react";
import companyLogo from "../assets/DKET Logo Full.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import styled from "styled-components";
import { NavLink as NavBarLink } from "react-router-dom";

const Nav = styled.nav`
  width: 100%;
  max-width: 800px;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    text-align: center;
    font-size: 12px;
  }
`;

const NavLink = styled(NavBarLink)`
  color: white;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: black;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const FooterLink = styled.a`
  color: white;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: black;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const EmailLink = styled.a`
margin-top: 10px;
  color: white;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  font-weight: 600;
  &:hover {
    color: black;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Footer = forwardRef((onScrollToFooter, ref) => {
  const scrollToFooter = () => {
    const footerSection = document.getElementById("footer");
    if (footerSection) {
      footerSection.scrollIntoView({ behavior: "auto" });
    }
  };
  return (
    <footer id="footer" ref={ref}>
        <div className="footer-section">
        <img src={companyLogo} alt="DKET Logo" className="footer-logo" loading="lazy"/>
        <div className="social-media-icons">
          <a href="https://instagram.com/dket_id" aria-label="Instagram">
            <InstagramIcon />
          </a>
          <a href="https://x.com/dket_id" aria-label="LinkedIn">
            <XIcon />
          </a>
        </div>
        <Nav>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about">About Us</NavLink>
          <NavLink to="/publication">Publication</NavLink>
          <NavLink to="/services">Services</NavLink>
          <FooterLink
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              scrollToFooter();
            }}
          >
            Contact Us
          </FooterLink>
        </Nav>
      </div>
    </footer>
  );
});

export default Footer;
